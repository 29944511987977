<template>
  <div class="section dark-section bujin-black-section">
    <section class="section  section-sm py-5 ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center pt-5">
              <h4 class="text-left">
                Searching: <strong>{{ query }}</strong>
                <img
                  class="float-right"
                  src="https://res.cloudinary.com/hilnmyskv/image/upload/q_auto/v1604064568/Algolia_com_Website_assets/images/shared/algolia_logo/search-by-algolia-dark-background.png"
                  alt="Search powered by Algolia"
                />
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section  section-lg pt-0">
      <SearchResults :hits="hits" />
    </section>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import SearchResults from "@/components/SearchResults";
export default {
  name: "Search",
  components: {
    SearchResults,
  },
  props: {
    query: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.search();
  },
  updated() {
    if (this.querylog != this.query) {
      this.search();
    }
  },
  data() {
    return {
      searchClient: algoliasearch(
        "3VNXMVRVMW",
        "9fbff50d3f552c6ab7fa2058303efb8d"
      ),
      hits: [],
      querylog: "",
      timer: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },

    actionMenu() {
      return this.$store.state.action_menu;
    },
  },

  methods: {
    search: function() {
      this.querylog = this.query;
      let index = this.searchClient.initIndex("bujin_programs");

      // console.log(this.query);
      index.search(this.query).then(({ hits }) => {
        this.hits = hits;
      });
    },
  },
};
</script>
