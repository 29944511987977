<template>
  <div class="container">
    <div class="row search-results">
      <div
        class="col-12 col-md-3 mb-3"
        v-for="(program, i) in hits"
        v-bind:key="i"
      >
        <!--        <img v-lazy="program.img_poster" :alt="program.title">-->

        <router-link
          :to="{
            name: 'media-detail',
            params: { slug: program.slug },
          }"
          class="d-block w-100 h-100 mb-3"
          :title="program.title"
          v-b-popover.hover.left="program.summary"
        >
          <img
            v-lazy="base + program.img_poster"
            class="w-100 rounded"
            :style="{
              objectFit: 'cover',
              objectPosition: 'center',
            }"
            :alt="program.title"
            :title="program.title"
          />
          <strong>{{ program.title }}</strong>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";

export default {
  name: "SearchResults",
  data() {
    return {
      base: "https://stream.bujin.tv/tenant0851fd69-1b37-4530-a80c-3676817e9472/",
    };
  },
  directives: {
    BPopover: VBPopover,
    VBTooltip,
  },
  props: {
    hits: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
